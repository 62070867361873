import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/styles/main.scss'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)

// app.use(ElementPlus)
const keyIcon = ''
const componentIcon = ''
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
 app.component(key, component)
}

// createApp(App)
 app.use(ElementPlus)
 .use(store)
 .use(router)
 .use(PerfectScrollbar)
 .mount('#app')
