import { createStore } from 'vuex'

const authHeaders = localStorage.getItem('olsp_auth_headers') ? JSON.parse(localStorage.getItem('olsp_auth_headers')) : {
  ['Authorization']: null,
  ['Device-Id']: null,
  ['Device-Type']: null,
  ['Token']: null
}

const tokenPayment = localStorage.getItem('lintas_token_payment') ? localStorage.getItem('lintas_token_payment') : null
const isActivePulangPergi = localStorage.getItem('lintas_active_pulang_pergi') ? localStorage.getItem('lintas_active_pulang_pergi') : 0
const dataPulang = localStorage.getItem('lintas_data_pulang') ? JSON.parse(localStorage.getItem('lintas_data_pulang')) : {}
const tagPageDisplay = localStorage.getItem('lintas_tag_display') ? localStorage.getItem('lintas_tag_display') : null

export default createStore({
  state: {
    authHeaders,
    tokenPayment,
    isActivePulangPergi,
    dataPulang,
    tagPageDisplay,
  },
  getters: {
    authHeaders: state => state.authHeaders,
    tokenPayment: state => state.tokenPayment,
    isActivePulangPergi: state => state.isActivePulangPergi,
    dataPulang: state => state.dataPulang,
    tagPageDisplay: state => state.tagPageDisplay,
  },
  mutations: {
    SET_TOKEN_PAYMENT(state, payload) {
      state.tokenPayment = payload
      localStorage.setItem('lintas_token_payment', payload)
    },
    REMOVE_TOKEN_PAYMENT(state) {
      state.tokenPayment = null
      localStorage.removeItem('lintas_token_payment')
    },

    SET_AUTH_HEADERS(state, payload) {
      state.authHeaders = payload
      localStorage.setItem('olsp_auth_headers', JSON.stringify(payload))
    },
    REMOVE_AUTH_HEADERS(state) {
      state.authHeaders = {
        ['Authorization']: null,
        ['Device-Id']: null,
        ['Device-Type']: null,
        ['Token']: null
      }
      localStorage.removeItem("olsp_auth_headers")
    },

    SET_ACTIVE_PULANG_PERGI(state, payload) {
      state.isActivePulangPergi = payload
      localStorage.setItem('lintas_active_pulang_pergi', payload)
    },
    REMOVE_ACTIVE_PULANG_PERGI(state) {
      state.isActivePulangPergi = null
      localStorage.removeItem('lintas_active_pulang_pergi')
    },

    SET_DATA_PULANG(state, payload) {
      state.dataPulang = payload
      localStorage.setItem('lintas_data_pulang', JSON.stringify(payload))
    },
    REMOVE_DATA_PULANG(state) {
      state.authHeaders = {}
      localStorage.removeItem("lintas_data_pulang")
    },
    SET_TAG_DISPLAY(state, payload) {
      state.tokenPayment = payload
      localStorage.setItem('lintas_tag_display', payload)
    },
    REMOVE_TAG_DISPLAY(state) {
      state.tagPageDisplay = null
      localStorage.removeItem('lintas_tag_display')
    },
  },
  actions: {
    setTokenPayment({ commit }, payload) {
      commit('SET_TOKEN_PAYMENT', payload)
    },
    removeTokenPayment({ commit }) {
      commit('REMOVE_TOKEN_PAYMENT')
    },
    setAuthHeaders({ commit }, payload) {
      commit('SET_AUTH_HEADERS', payload)
    },
    removeAuthHeaders({ commit }) {
      commit('REMOVE_AUTH_HEADERS')
    },
    setActivePulangPergi({ commit }, payload) {
      commit('SET_ACTIVE_PULANG_PERGI', payload)
    },
    removeActivePulangPergi({ commit }) {
      commit('REMOVE_ACTIVE_PULANG_PERGI')
    },
    setDataPulang({ commit }, payload) {
      commit('SET_DATA_PULANG', payload)
    },
    removeDataPulang({ commit }) {
      commit('REMOVE_DATA_PULANG')
    },
    setTagPageDisplay({ commit }, payload) {
      commit('SET_TAG_DISPLAY', payload)
    },
    removeTagPageDisplay({ commit }) {
      commit('REMOVE_TAG_DISPLAY')
    },
  },
  modules: {
  }
})
